//BlogIndex.page.tsx

import {
    SvgIcon,
    Typography,
    Button
} from "@mui/material";

import { Link } from "react-router-dom";
import { KeyboardBackspace, KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import "./BlogIndexPage.css";

import { useParams } from 'react-router-dom';
import blog1 from "../assets/img/blogs1.png";
import blog2 from "../assets/img/blogs2.png";
//import blog3 from "../assets/img/blogs3.png";
import blog4 from "../assets/img/blogs4.png";
import blog5 from "../assets/img/blogs5.png";
import blog6 from "../assets/img/blogs6.png";
import blog7 from "../assets/img/blogs7.png";
import blog8 from "../assets/img/blogs8.png";
import blog9 from "../assets/img/blogs9.png";
import blog10 from "../assets/img/blogs10.png";
import blog11 from "../assets/img/blogs11.png";
import blog12 from "../assets/img/blogs12.png";
import blog13 from "../assets/img/blogs13.png";
import { useState, useEffect } from "react";
import { IGetBlogs, IGetBlogsDet } from "../redux/api/types";
import { useGetBlogDetailsQuery, useGetBlogDetQuery, useBlogsCountQuery } from "../redux/api/promptApi";
import { LoadingButton } from "@mui/lab";



/* const blogs = [
    {
        title: "A Dive into Decentralization: How Code Genie Elevates Smart Contracts",
        image: <img className="card-img-top" src={blog1} alt="" />,
        text: "In the vast cosmos of technological advancements, decentralization emerges as the North Star, guiding countless innovations and reshaping the very bedrock of digital interactions. At the heart of this transformative paradigm is the realm of smart contracts—self-executing contracts with the agreement directly written into lines of code. But as powerful as they are, ensuring their security, efficiency, and functionality is a task that demands a unique blend of precision, speed, and foresight. This is where Code Genie enters the scene, not just as another tool but as a revolution in itself.",
        href: "/blogs/A_Dive_into_Decentralization",
    },
    {
        title: "The AI Advantage: Code Genie's Mastery Over Traditional Auditing Methods",
        image: <img className="card-img-top" src={blog2} alt="" />,
        text: "In the intricate landscape of blockchain technology, smart contracts stand as pillars of innovation, offering a transparent, tamper-proof way to codify and automate agreements. As powerful as they might be, these contracts are not immune to human errors, and any mistake can lead to devastating financial consequences. Enter the world of smart contract auditing, where the focus isn't just on detecting problems but predicting them, mitigating them, and ensuring the seamless execution of every agreement. While traditional auditing methods have made significant strides, they are increasingly being overshadowed by the prowess of AI-driven tools, with Code Genie leading the charge.",
        href: "/blogs/The_AI_Advantage",
    },
    {
        title: "Beyond Security: The Multi-Dimensional Benefits of Code Genie’s Audits",
        image: <img className="card-img-top" src={blog4} alt="" />,
        text: "At the heart of the decentralized revolution, smart contracts are changing the way we transact, trust, and transform digital agreements. Given their pivotal role in underpinning a myriad of applications, the security of these contracts naturally comes to the forefront. However, while security remains paramount, it's merely one dimension of a broader spectrum that defines a robust smart contract. With Code Genie's innovative approach to audits, the horizon expands beyond mere security checks, unveiling a comprehensive suite of benefits that position smart contracts for optimal performance and longevity.",
        href:"/blogs/Beyond_Security",
    },
    {
        title: "Unveiling Code Genie: Revolutionizing Smart Contract Audits in the Blockchain Era",
        image: <img className="card-img-top" src={blog5} alt="" />,
        text: "The world of smart contracts is intricate and complex. Ensuring their flawlessness is paramount, not just for functionality but also to fortify them against potential breaches and vulnerabilities. Enter Code Genie – a groundbreaking AI tool that is redefining how we perceive smart contract audits.",
        href:"/blogs/Unveiling_Code_Genie",
    },
    {
        title: "Cost-Effective Auditing: Exploring Code Genie's Affordability in Blockchain",
        image: <img className="card-img-top" src={blog6} alt="" />,
        text: "The blockchain sphere is witnessing an exponential surge in the deployment of smart contracts, paving the way for a new era of digital agreements. However, as the complexity and intricacy of these contracts grow, so does the need for rigorous auditing. Traditionally, this has translated into substantial financial investments, often becoming a deterrent for startups and small projects. Enter Code Genie - an innovative solution that's not only pushing the boundaries of audit precision but also making it economically viable for all. Let's embark on a journey to unpack the affordability of Code Genie's auditing services.",
        href:"/blogs/Cost_Effective_Auditing",
    },
    {
        title: "A Novice in Blockchain? Here's Why Code Genie is Your Best Bet",
        image: <img className="card-img-top" src={blog7} alt="" />,
        text: "Blockchain, with its disruptive potential, is luring enthusiasts from all walks of life. But as novices dive deep into this ocean of possibilities, they often find themselves grappling with the complexities of smart contracts. These self-executing contracts, albeit powerful, require meticulous attention to detail. The stakes are high, and the margin for error? Minuscule. If you find yourself tiptoeing on the edge of this intimidating precipice, Code Genie is here to offer a safety net. Here's how this revolutionary tool ensures that even those without a coding background can navigate the smart contract maze with confidence.",
        href:"/blogs/A_Novice_In_Blockchain",
    },
    {
        title: "One Click, Multiple Solutions: The Comprehensive Auditing of Code Genie",
        image: <img className="card-img-top" src={blog8} alt="" />,
        text: "The blockchain universe is rapidly expanding, and as it does, the complexity of navigating its intricacies grows in tandem. From startups to seasoned developers, everyone is in search of a tool that simplifies the labyrinthine world of smart contracts. Enter Code Genie, the unparalleled smart contract auditing solution that promises not just detection but holistic enhancement. With its one-click audit feature, Code Genie is revolutionizing the way we approach blockchain efficiency, security, and functionality. Let's delve deeper into how Code Genie's comprehensive auditing is setting new standards in the blockchain landscape.",
        href:"/blogs/One_Click_Multiple_Solutions",
    },
    {
        title: "Real-time Rectifications: Code Genie's Proactive Approach to Smart Contract Audits",
        image: <img className="card-img-top" src={blog9} alt="" />,
        text: "In the intricate web of blockchain technology, smart contracts have emerged as the pivotal touchpoint. Their seamless operation determines the efficacy and trustworthiness of blockchain applications. However, as vital as detection of issues is, the true mettle of an auditing tool lies in its rectification capabilities. Code Genie, a trailblazer in the blockchain auditing domain, exemplifies this paradigm by not only identifying but also rectifying smart contract issues in real-time. With the increasing search volume around \"proactive smart contract solutions\" and \"real-time blockchain fixes\", it's evident that the market seeks tools that do more than just highlight problems. Code Genie is perfectly positioned to meet this demand.",
        href:"/blogs/Real_time_Rectifications",
    },
    {
        title: "Upgrading the Upgradable: How Code Genie Ensures Smart Contract Evolution",
        image: <img className="card-img-top" src={blog10} alt="" />,
        text: "The dynamic realm of blockchain technology is akin to a constantly moving train – with new stations being added at each turn. Amidst this progression, smart contracts act as the tracks, guiding and ensuring smooth transitions. Just like rail tracks need upgrades for newer trains, smart contracts need upgradability for evolving requirements. Recognizing this pivotal aspect, Code Genie offers specialized audits that emphasize ensuring smart contract upgradability, fostering adaptability in a world where change is the only constant.",
        href:"/blogs/Upgrading_the_Upgradable",
    },
    {
        title: "Breaking Down the Technical: Code Genie’s Audit Explained",
        image: <img className="card-img-top" src={blog11} alt="" />,
        text: "The blockchain ecosystem, while fascinating, often seems like an intricate web of complexities to the untrained eye. One such element is smart contract auditing, a crucial component to ensure security, efficiency, and robustness in the ever-evolving blockchain universe. Enter Code Genie, a revolutionary tool reshaping the audit landscape. But how does Code Genie make the magic happen? Let's embark on a deep dive into the technicalities and unravel the mysteries behind its auditing magic",
        href:"/blogs/Breaking_Down_the_Technical",
    },
    {
        title: "The Ethical Edge: Code Genie's Commitment to Transparent Auditing",
        image: <img className="card-img-top" src={blog12} alt="" />,
        text: "In the digital age, as the blockchain realm expands at a blistering pace, trust becomes the bedrock of all technological interactions. The blockchain, lauded for its immutable transparency, is no exception. Yet, as layers of complexities unfold, ensuring transparency, especially in areas like smart contract auditing, poses a challenge. Enter Code Genie, the beacon of ethical, transparent, and honest smart contract auditing. This tool stands tall not just for its technical prowess but also for its unwavering commitment to ethical standards.",
        href:"/blogs/The_Ethical_Edge",
    },
    {
        title: "The Evolution of Audits: Tracing Code Genie’s Revolutionary Journey",
        image: <img className="card-img-top" src={blog13} alt="" />,
        text: "In the heart of the digital renaissance, the concept of smart contracts has emerged as a groundbreaking innovation. As these self-executing contracts gain traction, ensuring their accuracy and security is paramount. Enter the world of smart contract audits, a niche but a vital segment of the blockchain ecosystem. This article traces the transformative journey of smart contract auditing, spotlighting the revolutionary contributions of Code Genie.",
        href:"/blogs/The_Evolution_of_Audits",
    },
]; */

function RedirectToExternalSite() {

     const { url } = useParams();

    // Assuming you want to read a query parameter named "param1"
    const queryParams = new URLSearchParams(window.location.search);
    const catval = queryParams.get('cat');
    
    // Redirect to the external site
   //Accessing the domain name from the URL
    const domainName = window.location.hostname;
   
    // Redirect to the external site
    //if(domainName.indexOf('ai-literacy-act.ai')>=0 && (catval?.toLocaleUpperCase()=="CONSULTING" 
    //                                                  || catval?.toLocaleUpperCase()=="SMART CONTRACTS" || catval?.toLocaleUpperCase()=="ANY CODE" ))
    //    window.location.href = 'https://rohan-s-site-f544.thinkific.com/';
    //else if(catval?.toLocaleUpperCase()=="AI COURSE" 
    //                                                  || catval?.toLocaleUpperCase()=="SMART CONTRACTS" || catval?.toLocaleUpperCase()=="ANY CODE" )
    //if(catval?.toLocaleUpperCase()=="SMART CONTRACTS" || catval?.toLocaleUpperCase()=="ANY CODE" )
    //    window.location.href = 'https://consulting.code-genie.ai/';
    // You can also use Navigate component, but keep in mind it's intended for internal routing within your React app.
    // return <Navigate to="https://example.com" />;
    return null; // This component doesn't render anything, as the redirect happens via window.location.href
  }

const BlogIndexPage = () => {

    const { url } = useParams();

    // Assuming you want to read a query parameter named "param1"
    const queryParams = new URLSearchParams(window.location.search);
    const catval = queryParams.get('cat');
    if(catval?.toLocaleUpperCase()=="SMART CONTRACTS" || catval?.toLocaleUpperCase()=="ANY CODE" ){
        RedirectToExternalSite();
    }

    const [blogs, setBlogs] = useState<IGetBlogs[]>([]);
    const [blogDet, setBlogDet] = useState<IGetBlogsDet[]>([]);

    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 12; //Number of blogs per page
    const getBlogsCount = useBlogsCountQuery({cat:catval!,query:""});

    const getBlogs = useGetBlogDetailsQuery({limitval: pageSize, skipval: (currentPage - 1)*pageSize, cat:catval!});

   //console.log(getBlogs)
    
    
    useEffect(() => {
        if (getBlogs && getBlogs.data) {
            setBlogs([...getBlogs.data]);
        }
    }, [getBlogs]);

     // Pagination
     let totalPages = Math.ceil((getBlogsCount?.data ?? 0) / pageSize);

     const handleNextPage = () => {
         setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
     }
 
     const handlePrevPage = () => {
         setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))
     }
 
     const generatePageNumbers = (totalPages: number, currentPage: number): (number | '...')[] => {
         const pageNumbers: (number | '...')[] = [];
         for (let i = 1; i <= totalPages; i++) {
             if (i === 1 || i === totalPages || Math.abs(i - currentPage) < 3) {
                 pageNumbers.push(i);
             } else if (pageNumbers[pageNumbers.length - 1] !== '...') {
                 pageNumbers.push('...');
             }
         }
         return pageNumbers;
     };
 
     const pageNumbers = generatePageNumbers(totalPages, currentPage);
     //

    return (
        <>
            <div className="container-fluid my-5">
                <div className="my-5">
                    <h4 className="text-primary">News</h4>
                </div>
                <div className="row">
                    {blogs.map((blog, index) => (
                        <div className="col-md-4 mb-md-0 mb-3">
                            <div>
                                <div
                                    className="card p-3 text-white"
                                    style={{
                                        backgroundColor: "transparent",
                                        border: "1px solid #475569",
                                        marginBottom: "1.5rem"
                                    }}
                                >
                                        <div
                                        className="card p-3 text-white"
                                        style={{
                                            backgroundColor: "transparent",
                                            maxWidth: '453px', maxHeight: '279px', overflow: 'hidden'
                                        }}
                                    >
                                        <div className="card-img-top" style={{maxHeight:300,minHeight:300,overflow:'Hidden'}}><img className="card-img-top" src={blog.blog_image_path_small}  alt="" /></div>
    

                                    </div>
                                    <div className="card-body">
                                        <h4 className="card-title">
                                            {blog.blog_title || blog.blog_title_draft}
                                        </h4>
                                        <Typography
                                            className="card-text my-4"
                                            sx={{
                                                display: '-webkit-box',
                                                overflow: 'hidden',
                                                WebkitBoxOrient: 'vertical',
                                                WebkitLineClamp: 3,
                                            }}
                                            variant="body1">
                                            {blog.blog_summary || blog.blog_summary_draft}
                                        </Typography>
                                        <div className="back_border ps-3 py-1">
                                            {/* <a target="_blank" rel="noreferrer" href={blog.url} style={{  textDecoration: 'underline' }} onClick={() => handleReadMoreClick(blog._id)}>Read more</a> */}
                                            { catval=="SMART CONTRACTS"?  
                                                (<Link to={`/blogs/${blog.url || blog.url_draft}`}>Read More</Link>)
                                                : (<Link to={`/blogs/${blog.url || blog.url_draft}`}>Read More</Link>)
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                {/*<div className="text-center my-5">
                <button type="button" className="btn btn-outline-primary px-5">  <a target="_blank" href="/blogs" style={{ textDecoration: 'underline' }}> Read more blogs</a>
                </button>
                        </div>*/}
                        <div style={{ textAlign: 'center' }}>
                    <LoadingButton
                        startIcon={
                            <SvgIcon>
                                <KeyboardArrowLeft />
                            </SvgIcon>
                        }

                        sx={{
                            paddingRight: '15px'
                        }}
                        onClick={handlePrevPage}
                        disabled={currentPage === 1}
                    >

                    </LoadingButton>

                    {/* <span>{currentPage}</span> */}
                    {pageNumbers.map((page, index) => (
                        <span key={index} style={{ padding: '0 5px' }}>
                            <span
                                style={{
                                    cursor: page === '...' ? 'default' : 'pointer',
                                    fontWeight: page === currentPage ? 'bold' : 'normal'
                                }}
                                onClick={() => {
                                    if (typeof page === 'number') setCurrentPage(page);
                                }}
                            >
                                {page}
                            </span>
                        </span>
                    ))}

                    <LoadingButton
                        startIcon={
                            <SvgIcon>
                                <KeyboardArrowRight />
                            </SvgIcon>
                        }

                        sx={{
                            paddingLeft: '35px'
                        }}
                        onClick={handleNextPage}
                        disabled={blogs.length < pageSize}
                    >

                    </LoadingButton>
                </div>
            </div>

           {/*  <Link
                to="/"
                style={{
                    display: "flex",
                    marginTop: 40,
                    textDecoration: "none",
                    color: "black",
                }}
            >
                <SvgIcon sx={{ mr: 1 }}>
                    <KeyboardBackspace />
                </SvgIcon>
                Back to Home
            </Link> */}
        </>
    );
};

export default BlogIndexPage;
