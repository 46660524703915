import { createApi } from "@reduxjs/toolkit/query/react";
import customFetchBase from "./customFetchBase";

import { ProductSettingSaveInput } from "../../pages/admin/product.page";
import { PriceSettingSaveInput } from "../../pages/admin/price.page";
import {
  IpublishableKey,
  IPaymentIntent,
  IPayments,
  IUser,
  IPaymentHistory,
  ITotalUsers,
  ITotalUsersIncrease,
  ITotalRevenue,
  RevenueIncreasePercentage,
  TotalGoogleVisit,
  TotalGoogleIncreasePercentage,
  TotalUsers
} from "./types";

export const paymentApi = createApi({
  reducerPath: "paymentApi",
  baseQuery: customFetchBase,
  tagTypes: ["Payment"],
  endpoints: (builder) => ({
    getApiKey: builder.query<IpublishableKey, void>({
      //query: () => 'payment/config',
      query() {
        return {
          url: "payment/config",
          method: "GET",
        };
      },
    }),
    getClientSecret: builder.query<any, { amount: Number; currency: String }>({
      query: (arg) => {
        const { amount, currency } = arg;
        return {
          url: 'payment/create-payment-intent',
          params: { amount, currency },
          method: "GET",
        };
      },
    }),
    setPaymentUpdate: builder.query<any, { amount: number, currency: string, email: string }>({
      query: (arg) => {
        const { amount, currency, email } = arg;
        return {
          url: 'payment/payment-update',
          params: { amount, currency, email },
          method: "POST",
        };
      },
    }),

    getPaymentList: builder.query<IPaymentHistory, { email: string, skip: number, limit: number }>({

      query({ email, skip, limit }) {
        console.log("herere...1");
        return {
          url: `payment/get_payment_list/?email=${email}&skip=${skip}&limit=${limit}`,
          method: "POST",
        };
      },
    }),

    getTotalCountUsers: builder.query<TotalUsers, { }>({

      query() {
        console.log("herere...1");
        return {
          url: `payment/get_total_count_users/?`,
          method: "POST",
        };
      },
    }),

    getTotalRevenueSum: builder.query<TotalUsers, { }>({

      query() {
        console.log("herere...1");
        return {
          url: `payment/get_total_revenue_sum/`,
          method: "POST",
        };
      },
    }),

    getTotalUsers: builder.query<ITotalUsers, { from_date: string, to_date: string, mode: string, product: string }>({

      query({ from_date, to_date, mode, product }) {
        console.log("herere...1");
        return {
          url: `payment/get_total_users/?from_date=${from_date}&to_date=${to_date}&mode=${mode}&product=${product}`,
          method: "POST",
        };
      },
    }),

    getTotalUsersIncrease: builder.query<ITotalUsersIncrease, { from_date: string, to_date: string, mode: string, product: string }>({

      query({ from_date, to_date, mode, product }) {
        console.log("herere...1");
        return {
          url: `payment/get_total_users_increase_percent/?from_date=${from_date}&to_date=${to_date}&mode=${mode}&product=${product}`,
          method: "POST",
        };
      },
    }),

    getTotalRevenue: builder.query<ITotalRevenue, { from_date: string, to_date: string, mode: string, product: string }>({

      query({ from_date, to_date, mode, product }) {
        console.log("herere...1");
        return {
          url: `payment/get_total_revenue/?from_date=${from_date}&to_date=${to_date}&mode=${mode}&product=${product}`,
          method: "POST",
        };
      },
    }),

    getRevenueIncreasePercentage: builder.query<RevenueIncreasePercentage, { from_date: string, to_date: string, mode: string, product: string }>({

      query({ from_date, to_date, mode, product }) {
        console.log("herere...1");
        return {
          url: `payment/get_total_revenue_increase_percent/?from_date=${from_date}&to_date=${to_date}&mode=${mode}&product=${product}`,
          method: "POST",
        };
      },
    }),

    getTotalGoogleVisit: builder.query<TotalGoogleVisit, { from_date: string, to_date: string, mode: string, product: string }>({

      query({ from_date, to_date, mode, product }) {
        console.log("herere...1");
        return {
          url: `payment/get_total_google_visit/?from_date=${from_date}&to_date=${to_date}&mode=${mode}&product=${product}`,
          method: "POST",
        };
      },
    }),

    getTotalGoogleIncreasePercentage: builder.query<TotalGoogleIncreasePercentage, { from_date: string, to_date: string, mode: string, product: string }>({

      query({ from_date, to_date, mode, product }) {
        console.log("herere...1");
        return {
          url: `payment/get_total_google_visit_increase_percentage_visit/?from_date=${from_date}&to_date=${to_date}&mode=${mode}&product=${product}`,
          method: "POST",
        };
      },
    }),
  }),
}); 

export const {
  useGetApiKeyQuery,
  useLazyGetClientSecretQuery,
  useLazySetPaymentUpdateQuery,
  useGetPaymentListQuery,
  useGetTotalUsersQuery,
  useGetTotalUsersIncreaseQuery,
  useGetRevenueIncreasePercentageQuery,
  useGetTotalGoogleVisitQuery,
  useGetTotalGoogleIncreasePercentageQuery,
  useGetTotalRevenueQuery,
  useGetTotalCountUsersQuery,
  useGetTotalRevenueSumQuery
} = paymentApi;
