import { useState, useMemo } from "react";
import { CssBaseline } from "@mui/material";
import { Routes, Route, useLocation } from "react-router-dom";
import { PaletteMode } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import "react-toastify/dist/ReactToastify.css";

import { ToastContainer } from "react-toastify";
import Layout from "./components/Layout";
import { getDesignTokens, ColorModeContext } from "./theme";
import ProductConfigurator from "./pages/admin/product.page";
import PriceConfigurator from "./pages/admin/price.page";
import PromptConfigurator from "./pages/admin/prompt.page";
import Dashboard from "./pages/admin/dashboard.page";
import Subscribelist from "./pages/admin/subscribe.page";
import ContentGenerator from "./pages/admin/blogcreate.page";
import ContentEditor from "./pages/admin/blogedit.page";
import GeniePage from "./pages/genie.page";
import LandingPage from "./pages/landing.page";
import AnycodeLandingPage from "./pages/Anycode_landing";
import SignupPage from "./pages/auth/signup.page";
import TermsPage from "./pages/auth/terms.page";
import SigninPage from "./pages/auth/signin.page";
import ForgotPage from "./pages/auth/forgot.page";
import VerifyPage from "./pages/auth/verify.page";
import RequireUser from "./components/requireUser";
import UnauthorizePage from "./pages/unauthorized.page";
import PricingPage from "./pages/pricing.page";
import PrivacyPage from "./pages/auth/privacy.page";
import FeedbackPage from "./pages/feedback.page";
import PaymentPage from "./pages/payment/payment.page";
import SuccessPage from "./pages/payment/success.page";
import ADiveBlogs from "./blogs/A_Dive_into_Decentralization.blogs";
import ADiveBlogs1 from "./blogs/A_Dive_into_Decentralization.blogs1";
import ADiveintoBlogs from "./blogs/A_Dive_into.blogs";
import TheAIAdvantage from "./blogs/The_AI_Advantage.blogs";
import BeyondSecurity from "./blogs/Beyond_Security.blogs";
import UnveilingCodeGenie from "./blogs/Unveiling_Code_Genie.blogs";
import  CostEffectiveAuditing from "./blogs/Cost_Effective_Auditing.blogs";
import  ANoviceInBlockchain from "./blogs/A_Novice_In_Blockchain.blogs";
import  OneClickMultipleSolutions from "./blogs/One_Click_Multiple_Solutions.blogs";
import RealtimeRectifications from "./blogs/Real_time_Rectifications.blogs";
import UpgradingtheUpgradable from "./blogs/Upgrading_the_Upgradable.blogs";
import BreakingDowntheTechnical from "./blogs/Breaking_Down_the_Technical.blogs";
import TheEthicalEdge from "./blogs/The_Ethical_Edge.blogs";
import TheEvolutionofAudits from "./blogs/The_Evolution_of_Audits.blogs";
import BlogIndexPage from "./pages/BlogIndexPage";
import { Sitemap } from "./Sitemap";
import  Aboutus  from "./pages/auth/Aboutus.page";
import  Contactus  from "./pages/auth/contactus.page"
import { useParams } from "react-router-dom";
import './assets/css/bootstrap.min.css';
import ContactusPage from "./pages/contactus.page";
import Usersetting from "./pages/Usersetting";
import NotFoundPage from './pages/NotFound';


function App() {

  const [mode, setMode] = useState<PaletteMode>("dark");
 
  const userRole = localStorage.getItem('userRole');
  console.log('role of the user1=>' + userRole);
  

  const colorMode = useMemo(
    () => ({
      
        toggleColorMode: () => {
          debugger
          {userRole === 'admin' ? 
            setMode((prevMode: PaletteMode) =>
              prevMode === "light" ? "light" : "light"
            ) : setMode((prevMode: PaletteMode) =>
            prevMode === "light" ? "dark" : "dark"
          )}
      },
    }),
    []
  );

  function RedirectToExternalSite() {
    // Redirect to the external site
    //Accessing the domain name from the URL
    const domainName = window.location.hostname;

    // Redirect to the external site
    
    window.location.href = 'https://www.rohanhall.com/';
    // if(domainName.indexOf('ai-literacy-act.ai')>=0)
    //     window.location.href = 'https://rohan-s-site-f544.thinkific.com/';
    // else 
    //     //window.location.href = 'https://consulting.code-genie.ai/';
    // You can also use Navigate component, but keep in mind it's intended for internal routing within your React app.
    // return <Navigate to="https://example.com" />;
    return null; // This component doesn't render anything, as the redirect happens via window.location.href
  }

  const theme = useMemo(() => createTheme(getDesignTokens(mode)), [mode]);

  return (
    <>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <ToastContainer />
          <CssBaseline />
{/*          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/Anycode_landing" element={<AnycodeLandingPage />} />
            <Route path="sitemap" element={<Sitemap/>} />
            <Route path="/aboutus" element={<Aboutus/>} />
            <Route path="/aboutuspage" element={<Aboutus/>} />
            <Route path="/contactus" element={<ContactusPage/>} />
            <Route path="/contactuspage" element={<Contactus/>} />
            <Route element={<RequireUser allowedRoles={["admin", "user"]} />}>
              <Route path="/" element={<Layout type="user" page="" />}>
                <Route path="codegenie/:module" element={<GeniePage />} />
              </Route>
            </Route>
            <Route path="/" element={<Layout type="user" page="pricing" />}>
                <Route path="pricing" element={<PricingPage />} />
              </Route>
              
            <Route element={<RequireUser allowedRoles={["admin"]} />}>
              <Route path="/admin" element={<Layout type="admin" page="" />}>
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="subscribelist" element={<Subscribelist />} />
                <Route path="config">
                  <Route path="products" element={<ProductConfigurator />} />
                  <Route path="prompts" element={<PromptConfigurator />} />
                  <Route path="prices" element={<PriceConfigurator />} />
                </Route>
                <Route path="content">
                  <Route path="edit" element={<ContentEditor />} />
                </Route>
              </Route>
            </Route>
            <Route path="/signup" element={<SignupPage />} />
            <Route path="/signin" element={<SigninPage />} />
            <Route path="/verify" element={<VerifyPage page="signin" setPassCallback={()=> {return true}} />} />
            <Route path="/forgot" element={<ForgotPage />} />
            <Route path="/terms" element={<TermsPage />} />
            <Route path="/privacy" element={<PrivacyPage />} />
            <Route path="/feedback" element={<FeedbackPage />} />
            <Route path="/checkout" element={<PaymentPage />} />
           <Route path="/notfound" element={<NotFoundPage />} />
            <Route path="/codegenie/settings" element={<Usersetting />} />
            <Route path="/" element={<Layout type="user" page="pricing" />}>
              <Route path="success" element={<SuccessPage />} /> 
              <Route path="/blogs/:url" element={<ADiveBlogs1 />} />
              <Route path="/blogs1/:url" element={<ADiveBlogs1 />} />
              <Route path="/PreviewBlogs/:blog_id" element={<ADiveintoBlogs />} />
              <Route path="/blogs/The_AI_Advantage" element={<TheAIAdvantage />} />
              <Route path="/blogs/Beyond_Security" element={<BeyondSecurity />} />
              <Route path="/blogs/Unveiling_Code_Genie" element={<UnveilingCodeGenie />} />
              <Route path="/blogs/Cost_Effective_Auditing" element={<CostEffectiveAuditing />} />
              <Route path="/blogs/A_Novice_In_Blockchain" element={<ANoviceInBlockchain/>} />
              <Route path="/blogs/One_Click_Multiple_Solutions" element={<OneClickMultipleSolutions />} />
              <Route path="/blogs/Real_time_Rectifications" element={<RealtimeRectifications />} />
              <Route path="/blogs/Upgrading_the_Upgradable" element={<UpgradingtheUpgradable />} />
              <Route path="/blogs/Breaking_Down_the_Technical" element={<BreakingDowntheTechnical />} />
              <Route path="/blogs/The_Ethical_Edge" element={<TheEthicalEdge />} />
              <Route path="/blogs/The_Evolution_of_Audits" element={<TheEvolutionofAudits />} />
              <Route path="/blogs" element={<BlogIndexPage/>}/>
            </Route>
            <Route path="/unauthorized" element={<UnauthorizePage />} />
          </Routes>  */}

           
          <Routes>
            <Route path="/" element={<RedirectToExternalSite/>} />
            <Route path="/Anycode_landing" element={<RedirectToExternalSite/>} />
            <Route path="sitemap" element={<RedirectToExternalSite/>} />
            <Route path="/aboutus" element={<RedirectToExternalSite/>} />
            <Route path="/aboutuspage" element={<RedirectToExternalSite/>} />
            <Route path="/contactus" element={<RedirectToExternalSite/>} />
            <Route path="/contactuspage" element={<RedirectToExternalSite/>} />
            <Route element={<RequireUser allowedRoles={["admin", "user"]} />}>
              <Route path="/" element={<Layout type="user" page="" />}>
                <Route path="codegenie/:module" element={<RedirectToExternalSite />} />
              </Route>
            </Route>
            <Route path="/" element={<Layout type="user" page="pricing" />}>
                <Route path="pricing" element={<RedirectToExternalSite />} />
              </Route>
              
            <Route element={<RequireUser allowedRoles={["admin"]} />}>
              <Route path="/admin" element={<Layout type="admin" page="" />}>
                <Route path="dashboard" element={<RedirectToExternalSite />} />
                <Route path="subscribelist" element={<RedirectToExternalSite />} />
                <Route path="config">
                  <Route path="products" element={<RedirectToExternalSite />} />
                  <Route path="prompts" element={<RedirectToExternalSite />} />
                  <Route path="prices" element={<RedirectToExternalSite />} />
                </Route>
                <Route path="content">
                 
                  <Route path="edit" element={<RedirectToExternalSite />} />
                </Route>
              </Route>
            </Route>
            <Route path="/signup" element={<RedirectToExternalSite />} />
            <Route path="/signin" element={<RedirectToExternalSite />} />
            <Route path="/verify" element={<VerifyPage page="signin" setPassCallback={()=> {return true}} />} />
            <Route path="/forgot" element={<RedirectToExternalSite />} />
            <Route path="/terms" element={<RedirectToExternalSite />} />
            <Route path="/terms" element={<RedirectToExternalSite />} />
            <Route path="/privacy" element={<RedirectToExternalSite />} />
            <Route path="/feedback" element={<RedirectToExternalSite />} />
            <Route path="/checkout" element={<RedirectToExternalSite />} />
            <Route path="/codegenie/settings" element={<RedirectToExternalSite />} />
            <Route path="/" element={<Layout type="user" page="pricing" />}>
              <Route path="success" element={<RedirectToExternalSite />} /> 
              <Route path="/blogs/:url" element={<ADiveBlogs1 />} />
              <Route path="/blogs1/:url" element={<ADiveBlogs1 />} />
              <Route path="/PreviewBlogs/:blog_id" element={<RedirectToExternalSite />} />
              <Route path="/blogs/The_AI_Advantage" element={<RedirectToExternalSite />} />
              <Route path="/blogs/Beyond_Security" element={<RedirectToExternalSite />} />
              <Route path="/blogs/Unveiling_Code_Genie" element={<RedirectToExternalSite />} />
              <Route path="/blogs/Cost_Effective_Auditing" element={<RedirectToExternalSite />} />
              <Route path="/blogs/A_Novice_In_Blockchain" element={<RedirectToExternalSite/>} />
              <Route path="/blogs/One_Click_Multiple_Solutions" element={<RedirectToExternalSite />} />
              <Route path="/blogs/Real_time_Rectifications" element={<RedirectToExternalSite />} />
              <Route path="/blogs/Upgrading_the_Upgradable" element={<RedirectToExternalSite />} />
              <Route path="/blogs/Breaking_Down_the_Technical" element={<RedirectToExternalSite />} />
              <Route path="/blogs/The_Ethical_Edge" element={<RedirectToExternalSite />} />
              <Route path="/blogs/The_Evolution_of_Audits" element={<RedirectToExternalSite />} />
              <Route path="/blogs" element={<BlogIndexPage/>}/>
            </Route>
            <Route path="/unauthorized" element={<RedirectToExternalSite />} />
          </Routes>
        
        </ThemeProvider>
      </ColorModeContext.Provider>
    </>
  );
}

export default App;
