import { createApi } from "@reduxjs/toolkit/query/react";
import customFetchBase from "./customFetchBase";

import { ProductSettingSaveInput } from "../../pages/admin/product.page";
import { PriceSettingSaveInput } from "../../pages/admin/price.page";

import {
    ISubscription,
    ICreateSubscription
} from "./types";

export const subscriptionApi = createApi({
  reducerPath: "subscriptionApi",
  baseQuery: customFetchBase,
  tagTypes: ["Subscription"],
  endpoints: (builder) => ({
    createSubscription: builder.query<any, { email: String; price_id: String , stripeToken: String}>({
      query(arg) {
        const { email, price_id, stripeToken } = arg;
        return {
          url: "subscription/create-subscription",
          params: { email, price_id, stripeToken },
          method: "GET",
        };
      },
    }),
    createCheckoutSession: builder.query<any, { emailid: String, username: String, subscription_level: String}>({
      query(arg) {
        const { emailid, username, subscription_level} = arg;
        return {
          url: "subscription/create-checkout-session",
          params: { emailid, username, subscription_level },
          method: "POST",
        };
      },
    }),
    getOrderSuccess: builder.query<any, {session_id: String}>({
      query(arg) {
        const { session_id } = arg;
        return {
          url: "subscription/order/success",
          params: { session_id },
          method: "GET",
        };
      },
    }),
    reduceWishCount: builder.query<any, {userid: String}>({
      query(arg) {
        const { userid } = arg;
        return {
          url: "api/prompts/reduceWishCount",
          params: { userid },
          method: "GET",
        };
      },
    }),
  }),
  
});

export const {
  useLazyCreateSubscriptionQuery,
  useLazyCreateCheckoutSessionQuery,
  useGetOrderSuccessQuery,
  useReduceWishCountQuery,
} = subscriptionApi;
