import { createApi } from "@reduxjs/toolkit/query/react";
import customFetchBase from "./customFetchBase";



export const genieApi = createApi({
  reducerPath: "",
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    uploadImage: builder.mutation<{ content: string }, FormData>({
      query(data) {
        return {
          url: "genie/image",
          method: "POST",
          credentials: "include",
          body: data,
        };
      },
    }),
    sendUrl: builder.mutation<{ content: string }, { url: string }>({
      query(data) {
        return {
          url: "genie/url",
          method: "POST",
          credentials: "include",
          body: data,
        };
      },
    }),
    exportFile: builder.mutation<
      { path: string },
      { doc_type: string; module: string; advice: any }
    >({
      query({ doc_type, module, advice }) {
        return {
          url: `genie/export/${doc_type}/${module}`,
          method: "POST",
          credentials: "include",
          body: { advice },
        };
      },
    }),
    remainingWishCount: builder.query<any, {email: String}>({
      query(arg) {
        const { email } = arg;
        return {
          url: "genie/remaining_wish_count",
          params: { email },
          method: "POST",
        };
      },
    }),
    getUserDetails: builder.query<any, {email: String}>({
      query(arg) {
        const { email } = arg;
        return {
          url: "auth/getUserDetails",
          params: { email },
          method: "POST",
        };
      },
    }),

  }),
});

export const {
  useUploadImageMutation,
  useSendUrlMutation,
  useExportFileMutation,
  useRemainingWishCountQuery,
  useLazyRemainingWishCountQuery,
  useGetUserDetailsQuery,
} = genieApi;
