import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface GenieState {
  module?: string | null;
  prompt_name: string | null;
  msg: any;
  complexity: string | null;
}

const initialState: GenieState = {
  module: localStorage.getItem("module"),
  prompt_name: null,
  msg: {},
  complexity:"Low"
};



export const genieSlice = createSlice({
  name: "genieSlice",
  initialState,
  reducers: {
    setModule: (state, action: PayloadAction<string>) => {
      state.module = action.payload;
    },
    setPromptName: (state, action: PayloadAction<string>) => {
      state.prompt_name = action.payload;
    },
    setMsg: (state, action: PayloadAction<Object>) => {
      state.msg = action.payload;
    },
    setComplexity: (state, action: PayloadAction<string>) =>{
      //console.log()
      state.complexity = action.payload;
    }
  },
});

export const { setModule, setPromptName, setMsg, setComplexity } = genieSlice.actions;

export default genieSlice.reducer;
