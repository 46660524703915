import { createApi } from "@reduxjs/toolkit/query/react";
import customFetchBase from "./customFetchBase";
import {
  IContactus,
  IGenericResponse,
  ISigninReseponseSchema,
  ISocialSignupSchema,
  IUpdatePassword,
  IUserLists,
  ActivateUser,
  DeactivateUser,
  ISubscribeNews,
  UnSubscribePayment,
  GetSubscribers,
  Deletesubscriber
} from "./types";
import { SignupInput } from "../../pages/auth/signup.page";
import { SigninInput } from "../../pages/auth/signin.page";
import { ForgotPassInput, ResetInput } from "../../pages/auth/forgot.page";
import { userApi } from "./userApi";
import { IFeedback } from "./types";
import { FeedBackForm } from "../../components/genie/TotalPanel";
import { ContactUsForm } from "../../pages/contactus.page";
import { updatepasswordval } from "../../pages/Usersetting";
import { NewsSubscriptionForm } from "../../pages/Smart_contract_landingpage";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    socialAuth: builder.mutation<
      { access_token: string; role: string },
      ISocialSignupSchema
    >({
      query(data) {
        return {
          url: "auth/social",
          method: "POST",
          body: data,
          credentials: "include",
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          await dispatch(userApi.endpoints.getMe.initiate(null));
        } catch (error) { }
      },
    }),
    signupUser: builder.mutation<IGenericResponse, SignupInput>({
      query(data) {
        return {
          url: "auth/signup",
          method: "POST",
          body: data,
        };
      },
    }),
    /* signinUser: builder.mutation<ISigninReseponseSchema, SigninInput>({
      query(data) {
        return {
          url: "auth/signin",
          method: "POST",
          body: data,
          credentials: "include",
        };
      } */
      signinUser: builder.mutation<ISigninReseponseSchema, SigninInput>({
        query(data) {
          return {
            url: `auth/signin`,/* ?client_ip=${data.client_ip} */
            method: "POST",
            body: data,
            credentials: "include",
          };
        },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          await dispatch(userApi.endpoints.getMe.initiate(null));
        } catch (error) { }
      },
    }),
    verifyEmail: builder.mutation<IGenericResponse, { code: string }>({
      query(data) {
        return {
          url: "auth/verify",
          method: "PATCH",
          body: data,
        };
      },
    }),
    forgotPassword: builder.mutation<IGenericResponse, ForgotPassInput>({
      query(data) {
        return {
          url: "auth/forgotPass",
          method: "POST",
          body: data,
        };
      },
    }),
    ResetPassword: builder.mutation<IGenericResponse, ResetInput>({
      query(data) {
        return {
          url: "auth/resetPass",
          method: "POST",
          body: data,
        };
      },
    }),

    FeedBack: builder.mutation<IFeedback, FeedBackForm>({
      query(data) {
        return {
          url: "auth/sendFeedback",
          method: "POST",
          body: data,
        };
      },
    }),
    Contactus: builder.mutation<IContactus, ContactUsForm>({
      query(data) {
        return {
          url: "auth/contactus",
          method: "POST",
          body: data,
        };
      },
    }),

    SubscribeNews: builder.mutation<ISubscribeNews, NewsSubscriptionForm>({
      query(data) {
        return {
          url: "auth/subscribefornews",
          method: "POST",
          body: data,
        };
      },
    }),

    UnSubscribepayment: builder.mutation<UnSubscribePayment, { email: string }>({
      query({email}) {
        return {
          url: `payment/unsubscribepayment/?email=${email}`,
          method: "POST",
        };
      },
    }),

    UpdatePass: builder.mutation<IUpdatePassword, updatepasswordval>({
      query(data) {
        return {
          url: "auth/updatePass",
          method: "POST",
          body: data,
        };
      },
    }),

    UsersList: builder.mutation<IUserLists, { skip: number, limit: number, from_date: string, to_date: string, search_str: string }>({
      query({ skip, limit, from_date, to_date, search_str }) {
        return {
          url: `auth/getUserList?skip=${skip}&limit=${limit}&from_date=${from_date}&to_date=${to_date}&search_str=${search_str}`,
          method: "POST",
          // body: data,
        };
      },
    }),

   

    DeleteSubscriber: builder.mutation<Deletesubscriber, { email: string }>({
      query({email}) {
        return {
          url: `auth/delete_subscriber?email=${email}`,
          method: "GET",
        };
      },
    }),

    ActiveUser: builder.mutation<ActivateUser, { email: string }>({
      query({email}) {
        return {
          url: `auth/activate_user?email=${email}`,
          method: "GET",
        };
      },
    }),

    DeactiveUser: builder.mutation<DeactivateUser, { email: string }>({
      query({email}) {
        return {
          url: `auth/deactivate_user?email=${email}`,
          method: "GET",
        };
      },
    }),

    GetSubscribers: builder.mutation<GetSubscribers, { skip: number, limit: number, from_date: string, to_date: string, search_str: string }>({
      query({ skip, limit, from_date, to_date, search_str }) {
        return {
          url: `auth/getSubscribers?skip=${skip}&limit=${limit}&from_date=${from_date}&to_date=${to_date}&search_str=${search_str}`,
          method: "POST",
        };  
      },
    }),


  }),

});

export const {
  useSocialAuthMutation,
  useSignupUserMutation,
  useSigninUserMutation,
  useVerifyEmailMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useFeedBackMutation,
  useContactusMutation,
  useUpdatePassMutation,
  useUsersListMutation,
  useActiveUserMutation,
  useDeactiveUserMutation,
  useSubscribeNewsMutation,
  useGetSubscribersMutation,
  useDeleteSubscriberMutation,
  useUnSubscribepaymentMutation
} = authApi;
