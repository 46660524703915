import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IPayments } from "../api/types";

interface IPaymentState {
    payments: IPayments | null;
}

const initialState: IPaymentState = {
    payments: null,
};

export const paymentSlice = createSlice({
  initialState,
  name: "paymentSlice",
  reducers: {
    setPayment: (state, action: PayloadAction<IPayments>) => {
      state.payments = action.payload;
    },
  },
});

export default paymentSlice.reducer;

export const { setPayment } = paymentSlice.actions;
